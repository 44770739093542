:root {
  --color-primary-100: 245 210 248;
  --color-primary-300: 180 111 222;
  --color-primary-500: 137 55 179;
  --color-primary-700: 96 30 130;
  --color-primary-900: 61 26 79;
  --color-secondary-100: 245 233 245;
  --color-secondary-300: 242 194 242;
  --color-secondary-500: 206 74 206;
  --color-secondary-700: 128 41 125;
  --color-secondary-900: 94 0 90;
  --color-info-100: 208 242 255;
  --color-info-300: 116 202 255;
  --color-info-500: 24 144 255;
  --color-info-700: 12 83 183;
  --color-info-900: 4 41 122;
  --color-success-100: 233 252 212;
  --color-success-300: 170 242 127;
  --color-success-500: 84 214 44;
  --color-success-700: 34 154 22;
  --color-success-900: 8 102 13;
  --color-warning-100: 255 247 205;
  --color-warning-300: 255 225 106;
  --color-warning-500: 255 193 7;
  --color-warning-700: 183 129 3;
  --color-warning-900: 122 79 1;
  --color-error-100: 255 231 217;
  --color-error-300: 255 164 141;
  --color-error-500: 255 72 66;
  --color-error-700: 183 33 54;
  --color-error-900: 122 12 46;
  --color-gray-100: 249 250 251;
  --color-gray-200: 244 246 248;
  --color-gray-300: 223 227 232;
  --color-gray-400: 196 205 213;
  --color-gray-500: 145 158 171;
  --color-gray-600: 99 115 129;
  --color-gray-700: 69 79 91;
  --color-gray-800: 33 43 54;
  --color-gray-900: 22 28 36;
  --color-white: 255 255 255;
  --color-facebook: 24 119 242;
  --color-instagram: 224 45 105;
  --color-linkedin: 0 126 187;
  --color-twitter: 0 170 236;
  --color-purpura-50: 255 198 255;
  --color-purpura-100: 255 175 255;
  --color-purpura-200: 251 153 255;
  --color-purpura-300: 227 131 255;
  --color-purpura-400: 203 109 255;
  --color-purpura-500: 179 87 255;
  --color-purpura-600: 155 64 235;
  --color-purpura-700: 132 40 211;
  --color-purpura-800: 108 8 189;
  --color-purpura-900: 84 0 166;
  --color-profiler-communicator: 255 186 0;
  --color-profiler-executor: 202 32 55;
  --color-profiler-planner: 0 167 96;
  --color-profiler-analyst: 38 87 237;
  --color-copilot-primary: 142 58 225;
  --color-copilot-primary-hover: 54 20 109;
  --color-copilot-chip: 97 36 158;
  --color-copilot-extra-light: 233 211 255;
}

.material-icons::before,
.material-icons-outlined::before,
.material-icons-round::before,
.material-icons-sharp::before,
.material-icons-two-tone::before {
  content: attr(data-icon);
}