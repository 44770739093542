@import url('./components/menu-toggle.css');
@import url('./components/theme.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body, button, textarea, input, tr {
  font: 400 1rem "Lexend", sans-serif;
}

body {
  background-color: #fff;
}

a {
  color: inherit;
  text-decoration: none;
}

fieldset {
  border: 0;
}

button:hover {
  cursor: pointer;
}

.chat-container-override,
#chatbase-bubble-button {
  bottom: 95px !important;
}

#chatbase-message-bubbles {
  bottom: 150px !important;
}

@media screen and (min-width: 600px) {
  #chatbase-bubble-window {
    height: 65vh !important;
    bottom: 160px !important;
  }
}

.scroll::-webkit-scrollbar {
  width: 8px;
}

.scroll::-webkit-scrollbar-track {
  background-color: rgba(223, 227, 232, 1);
  border-radius: 20px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: rgba(196, 205, 213, 1);
  border-radius: 20px;
}

nav[aria-label="Pagination"] > button.bg-primary-100, nav[aria-label="Pagination"] > button:hover {
  background: #ddd;
}

